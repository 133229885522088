export enum PeriodType {
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month',
  CUSTOM = 'custom'
}

export enum SubPeriodType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}

export interface BasicPeriod {
  periodId: string
  periodType: PeriodType
  dateRange: string[]
  isFuture: boolean
}

export interface Period extends BasicPeriod {
  compareDateRange: string[]
  trendDateRange: string[]
}

export interface FullPeriod extends Period {
  trendSubPeriod: SubPeriodType
  chartSubPeriod: SubPeriodType
}
