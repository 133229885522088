import { MEASURE_TITLES, DIMENSION_TITLES } from '~/constants/analytics'
import { MeasureCategory, DimensionCategory, MemberFormat } from '~/types/cube'
import { DataType, MemberType } from '~/types/analytics'
import { TagTheme } from '~/types/tagTheme'

export const THEME_BY_DATA_TYPE: { [key in DataType]: TagTheme } = {
  [DataType.EMPLOYEE]: TagTheme.PRIMARY,
  [DataType.RECRUITMENT]: TagTheme.SECONDARY
}

export const EMPTY_VALUE = '∅'

export enum AnalyticsCube {
  ORGANIZATION = 'Organization',
  EMPLOYEE_DAILY_SNAPSHOTS = 'EmployeeDailySnapshots',
  ABSENCE_DAILY_SNAPSHOTS = 'AbsenceDailySnapshots',
  PAYROLL_TRANSACTIONS = 'PayrollTransactions',
  JOB_APPLICATION_DAILY_SNAPSHOTS = 'JobApplicationDailySnapshots',
  JOB_DAILY_SNAPSHOTS = 'JobDailySnapshots',
  ORGANIZATION_QUARTERLY_SNAPSHOTS = 'OrganizationQuarterlySnapshots',
  EMPLOYEE_EGAPRO_SNAPSHOTS = 'EmployeeEgaproSnapshots'
}

export const DEFAULT_MAIN_CUBES = [
  AnalyticsCube.EMPLOYEE_DAILY_SNAPSHOTS,
  AnalyticsCube.JOB_APPLICATION_DAILY_SNAPSHOTS
]

export const CUBE_DATA_TYPES: { [key in AnalyticsCube]: DataType | null } = {
  [AnalyticsCube.ORGANIZATION]: null,
  [AnalyticsCube.EMPLOYEE_DAILY_SNAPSHOTS]: DataType.EMPLOYEE,
  [AnalyticsCube.ABSENCE_DAILY_SNAPSHOTS]: DataType.EMPLOYEE,
  [AnalyticsCube.PAYROLL_TRANSACTIONS]: DataType.EMPLOYEE,
  [AnalyticsCube.JOB_APPLICATION_DAILY_SNAPSHOTS]: DataType.RECRUITMENT,
  [AnalyticsCube.JOB_DAILY_SNAPSHOTS]: DataType.RECRUITMENT,
  [AnalyticsCube.ORGANIZATION_QUARTERLY_SNAPSHOTS]: null,
  [AnalyticsCube.EMPLOYEE_EGAPRO_SNAPSHOTS]: null
}

export const AVAILABLE_MEASURE_CATEGORIES = [
  MeasureCategory.WORKFORCE,
  MeasureCategory.RETENTION,
  MeasureCategory.DEPARTURE,
  MeasureCategory.ARRIVAL,
  MeasureCategory.CAREER,
  MeasureCategory.MANAGEMENT,
  MeasureCategory.SALARY,
  MeasureCategory.TRIAL_PERIOD,
  MeasureCategory.FIRST_YEAR,
  MeasureCategory.ABSENCE,
  MeasureCategory.PAYROLL,
  MeasureCategory.ORGANIZATION,
  MeasureCategory.OVERVIEW,
  MeasureCategory.FUNNEL,
  MeasureCategory.SPEED,
  MeasureCategory.RECRUITER
]

export const DATA_TYPE_BY_MEASURE_CATEGORY: {
  [key in MeasureCategory]: DataType
} = {
  [MeasureCategory.WORKFORCE]: DataType.EMPLOYEE,
  [MeasureCategory.RETENTION]: DataType.EMPLOYEE,
  [MeasureCategory.DEPARTURE]: DataType.EMPLOYEE,
  [MeasureCategory.ARRIVAL]: DataType.EMPLOYEE,
  [MeasureCategory.CAREER]: DataType.EMPLOYEE,
  [MeasureCategory.MANAGEMENT]: DataType.EMPLOYEE,
  [MeasureCategory.SALARY]: DataType.EMPLOYEE,
  [MeasureCategory.TRIAL_PERIOD]: DataType.EMPLOYEE,
  [MeasureCategory.FIRST_YEAR]: DataType.EMPLOYEE,
  [MeasureCategory.ABSENCE]: DataType.EMPLOYEE,
  [MeasureCategory.PAYROLL]: DataType.EMPLOYEE,
  [MeasureCategory.ORGANIZATION]: DataType.EMPLOYEE,
  [MeasureCategory.GENDER]: DataType.EMPLOYEE,
  [MeasureCategory.OVERVIEW]: DataType.RECRUITMENT,
  [MeasureCategory.FUNNEL]: DataType.RECRUITMENT,
  [MeasureCategory.SPEED]: DataType.RECRUITMENT,
  [MeasureCategory.RECRUITER]: DataType.RECRUITMENT
}

export const MEASURE_CATEGORY_EMOJIS: { [key in MeasureCategory]: string } = {
  [MeasureCategory.ABSENCE]: '🌙',
  [MeasureCategory.ARRIVAL]: '🚀',
  [MeasureCategory.CAREER]: '⌛',
  [MeasureCategory.DEPARTURE]: '👋',
  [MeasureCategory.ORGANIZATION]: '🏢',
  [MeasureCategory.PAYROLL]: '💸',
  [MeasureCategory.SALARY]: '💰',
  [MeasureCategory.MANAGEMENT]: '👩‍🏫',
  [MeasureCategory.TRIAL_PERIOD]: '👶',
  [MeasureCategory.WORKFORCE]: '🔄',
  [MeasureCategory.RETENTION]: '🚪',
  [MeasureCategory.FIRST_YEAR]: '🆕',
  [MeasureCategory.RECRUITER]: '👩‍💼',
  [MeasureCategory.SPEED]: '⏱️',
  [MeasureCategory.OVERVIEW]: '📊',
  [MeasureCategory.FUNNEL]: '🎯',
  [MeasureCategory.GENDER]: '🚻'
}

export const AVAILABLE_DIMENSION_CATEGORIES = [
  DimensionCategory.TIME,
  DimensionCategory.EMPLOYEE_ORGANIZATION,
  DimensionCategory.EMPLOYEE_CONTRACT,
  DimensionCategory.EMPLOYEE_INFO,
  DimensionCategory.EMPLOYEE_DEPARTURE,
  DimensionCategory.EMPLOYEE_ARRIVAL,
  DimensionCategory.EMPLOYEE_CAREER,
  DimensionCategory.EMPLOYEE_MANAGER,
  DimensionCategory.EMPLOYEE_SALARY,
  DimensionCategory.EMPLOYEE_TRIAL_PERIOD,
  DimensionCategory.EMPLOYEE_ABSENCE,
  DimensionCategory.EMPLOYEE_PAYROLL,
  DimensionCategory.RECRUITMENT_ORGANIZATION,
  DimensionCategory.RECRUITMENT_INFO,
  DimensionCategory.RECRUITMENT_SOURCE,
  DimensionCategory.RECRUITMENT_SPEED,
  DimensionCategory.RECRUITMENT_STAGE,
  DimensionCategory.RECRUITMENT_TIMELINE,
  DimensionCategory.RECRUITMENT_REJECTION,
  DimensionCategory.RECRUITMENT_CANDIDATE,
  DimensionCategory.RECRUITMENT_RECRUITER
]

export const DATA_TYPE_BY_DIMENSION_CATEGORY: {
  [key in DimensionCategory]: DataType | null
} = {
  [DimensionCategory.TIME]: null,
  [DimensionCategory.EMPLOYEE_ORGANIZATION]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_CONTRACT]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_INFO]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_DEPARTURE]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_ARRIVAL]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_CAREER]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_MANAGER]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_SALARY]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_TRIAL_PERIOD]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_ABSENCE]: DataType.EMPLOYEE,
  [DimensionCategory.EMPLOYEE_PAYROLL]: DataType.EMPLOYEE,
  [DimensionCategory.RECRUITMENT_ORGANIZATION]: DataType.RECRUITMENT,
  [DimensionCategory.RECRUITMENT_INFO]: DataType.RECRUITMENT,
  [DimensionCategory.RECRUITMENT_SOURCE]: DataType.RECRUITMENT,
  [DimensionCategory.RECRUITMENT_SPEED]: DataType.RECRUITMENT,
  [DimensionCategory.RECRUITMENT_STAGE]: DataType.RECRUITMENT,
  [DimensionCategory.RECRUITMENT_TIMELINE]: DataType.RECRUITMENT,
  [DimensionCategory.RECRUITMENT_REJECTION]: DataType.RECRUITMENT,
  [DimensionCategory.RECRUITMENT_CANDIDATE]: DataType.RECRUITMENT,
  [DimensionCategory.RECRUITMENT_RECRUITER]: DataType.RECRUITMENT
}

export const DIMENSION_CATEGORY_EMOJIS: { [key in DimensionCategory]: string } =
  {
    [DimensionCategory.TIME]: '📅',
    [DimensionCategory.EMPLOYEE_ORGANIZATION]: '🔍',
    [DimensionCategory.EMPLOYEE_CONTRACT]: '📝',
    [DimensionCategory.EMPLOYEE_INFO]: '👤',
    [DimensionCategory.EMPLOYEE_DEPARTURE]: '👋',
    [DimensionCategory.EMPLOYEE_ARRIVAL]: '🚀',
    [DimensionCategory.EMPLOYEE_CAREER]: '⌛',
    [DimensionCategory.EMPLOYEE_MANAGER]: '👩‍🏫',
    [DimensionCategory.EMPLOYEE_SALARY]: '💰',
    [DimensionCategory.EMPLOYEE_TRIAL_PERIOD]: '👶',
    [DimensionCategory.EMPLOYEE_ABSENCE]: '🌙',
    [DimensionCategory.EMPLOYEE_PAYROLL]: '💸',
    [DimensionCategory.RECRUITMENT_ORGANIZATION]: '🤝',
    [DimensionCategory.RECRUITMENT_INFO]: '🗂️',
    [DimensionCategory.RECRUITMENT_SOURCE]: '🧭',
    [DimensionCategory.RECRUITMENT_SPEED]: '⏱️',
    [DimensionCategory.RECRUITMENT_STAGE]: '👣',
    [DimensionCategory.RECRUITMENT_TIMELINE]: '🕒',
    [DimensionCategory.RECRUITMENT_REJECTION]: '⛔',
    [DimensionCategory.RECRUITMENT_CANDIDATE]: '👤',
    [DimensionCategory.RECRUITMENT_RECRUITER]: '👩‍💼'
  }

export const ICON_BY_FORMAT: { [key in MemberFormat]: string } = {
  [MemberFormat.NUMBER_0]: 'hashtag',
  [MemberFormat.NUMBER_1]: 'hashtag',
  [MemberFormat.NUMBER_2]: 'hashtag',
  [MemberFormat.PERCENT]: 'percent',
  [MemberFormat.PERCENT_0]: 'percent',
  [MemberFormat.CURRENCY]: 'dollar',
  [MemberFormat.CURRENCY_EXACT]: 'dollar',
  [MemberFormat.DATE]: 'date',
  [MemberFormat.NAME]: 'text',
  [MemberFormat.TRANSLATION_KEYS]: 'text',
  [MemberFormat.DIMENSION_TITLES]: 'text',
  [MemberFormat.BOOLEAN]: 'flag',
  [MemberFormat.DAYS]: 'timer',
  [MemberFormat.AGE_YEARS]: 'timer',
  [MemberFormat.AGE_YEARS_MONTHS_DAYS]: 'timer'
}

export const SUGGESTED_OPTIONS_BY_MEMBER_TYPE: {
  [key in MemberType]: string[]
} = {
  [MemberType.MEASURE]: [
    MEASURE_TITLES.EMPLOYEE_COUNT_END,
    MEASURE_TITLES.EMPLOYEE_COUNT_ARRIVAL,
    MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE,
    MEASURE_TITLES.EMPLOYEE_ATTRITION,
    MEASURE_TITLES.EMPLOYEE_COUNT_END_FTE_HR,
    MEASURE_TITLES.EMPLOYEE_MEDIAN_SALARY_TOTAL_EUR,
    MEASURE_TITLES.RECRUITMENT_COUNT_HIRE,
    MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_HIRE,
    MEASURE_TITLES.RECRUITMENT_OFFER_ACCEPTANCE_RATE
  ],
  [MemberType.DIMENSION]: [
    DIMENSION_TITLES.EMPLOYEE_DEPARTMENT,
    DIMENSION_TITLES.EMPLOYEE_JOB_TITLE,
    DIMENSION_TITLES.EMPLOYEE_IS_MANAGER,
    DIMENSION_TITLES.EMPLOYEE_SENIORITY_IN_YEARS,
    DIMENSION_TITLES.EMPLOYEE_AGE_RANGE,
    DIMENSION_TITLES.EMPLOYEE_NB_DAYS_FOR_CURRENT_JOB_TITLE,
    DIMENSION_TITLES.EMPLOYEE_SALARY_TOTAL_RANGE_EUR,
    DIMENSION_TITLES.JOB_TITLE,
    DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_HIRE,
    DIMENSION_TITLES.JOB_APPLICATION_HIRING_SOURCE,
    DIMENSION_TITLES.JOB_APPLICATION_RECRUITER_DISPLAY_NAME,
    DIMENSION_TITLES.JOB_APPLICATION_REJECTION_REASON
  ],
  [MemberType.GROUP]: [
    DIMENSION_TITLES.EMPLOYEE_GENDER,
    DIMENSION_TITLES.EMPLOYEE_AGE_RANGE,
    DIMENSION_TITLES.EMPLOYEE_IS_MANAGER,
    DIMENSION_TITLES.EMPLOYEE_SENIORITY_IN_YEARS,
    DIMENSION_TITLES.EMPLOYEE_SALARY_TOTAL_RANGE_EUR,
    DIMENSION_TITLES.JOB_APPLICATION_IS_OFFERED,
    DIMENSION_TITLES.JOB_APPLICATION_HIRING_SOURCE_TYPE,
    DIMENSION_TITLES.JOB_DEPARTMENT,
    DIMENSION_TITLES.JOB_APPLICATION_REJECTION_REASON
  ]
}
