import { useFiltersStore } from '~/stores/filters'
import {
  getCurrentQuarterPeriodOptions,
  buildCompletePeriodFromPeriodOption,
  getPeriodFromDateRange
} from '~/services/period'
import {
  type FullPeriod,
  type Period,
  PeriodType,
  SubPeriodType
} from '~/types/timeDimension'
import { replaceRouteQueryParam } from '~/services/router'
import { QUERY_PARAMETERS } from '~/types/queryParameters'

interface State {
  selectedPeriod: FullPeriod
  options: Period[]
}

export const useTimeDimensionStore = defineStore('timeDimension', {
  state: (): State => ({
    selectedPeriod: {
      periodId: 'QTD',
      periodType: PeriodType.QUARTER,
      dateRange: [],
      isFuture: false,
      compareDateRange: [],
      trendDateRange: [],
      trendSubPeriod: SubPeriodType.MONTH,
      chartSubPeriod: SubPeriodType.WEEK
    },
    options: getCurrentQuarterPeriodOptions()
  }),
  actions: {
    async setSelectedPeriodFromPeriodId({
      periodId = 'QTD',
      shouldModifyUrlParams = true
    }) {
      const periodOption = this.options.find(
        periodOption => periodOption.periodId === periodId
      )!

      const newPeriod = buildCompletePeriodFromPeriodOption(periodOption)

      shouldModifyUrlParams &&
        (await replaceRouteQueryParam([
          [QUERY_PARAMETERS.DASHBOARD_START, newPeriod.dateRange[0]],
          [QUERY_PARAMETERS.DASHBOARD_END, newPeriod.dateRange[1]]
        ]))

      this.selectedPeriod = newPeriod
      const filtersStore = useFiltersStore()
      await filtersStore.getStandardFiltersOptions()
    },
    async setSelectedPeriodFromDateRange({
      dateRange,
      shouldModifyUrlParams = true
    }: {
      dateRange: string[]
      shouldModifyUrlParams?: boolean
    }) {
      const newPeriod = getPeriodFromDateRange(dateRange, this.options)

      shouldModifyUrlParams &&
        (await replaceRouteQueryParam([
          [QUERY_PARAMETERS.DASHBOARD_START, newPeriod.dateRange[0]],
          [QUERY_PARAMETERS.DASHBOARD_END, newPeriod.dateRange[1]]
        ]))

      this.selectedPeriod = newPeriod
      const filtersStore = useFiltersStore()
      await filtersStore.getStandardFiltersOptions()
    }
  }
})
