import { getMultiYearComparePeriods } from '~/services/period'
import {
  getFormattedMainTimeDimensionPeriod,
  getFormattedSecondaryTimeDimensionPeriod,
  getGranularityFromDimensions
} from '~/services/timeDimension'
import { getMeasureFromTitle } from '~/services/explore'
import { formatValue } from '~/services/format'
import { ANALYTICS_GET_QUERY } from '~/gql/analytics/analyticsGetQuery'
import { ANALYTICS_GET_OPTIONS } from '~/gql/analytics/analyticsGetOptions'
import { ANALYTICS_GET_FILTER_OPERATORS_FOR_MEMBER } from '~/gql/analytics/analyticsGetFilterOperatorsForMember'
import {
  QueryTimeType,
  type DimensionValue,
  type Filter,
  type QueryTimeOption
} from '~/types/analytics'
import {
  FilterOperator,
  OrderValue,
  type Order,
  type Dimension
} from '~/types/cube'
import { DIMENSION_TITLES } from '~/constants/analytics'
import { SubPeriodType, type FullPeriod } from '~/types/timeDimension'
import type {
  PartialQuery,
  Query,
  ResultSet,
  ResultSetItemValue
} from '~/types/query'
import { EMPTY_VALUE } from '~/constants/cube'
import {
  DIMENSION_TITLES_BY_TIME_GRAIN,
  MAIN_TIME_DIMENSION_TITLES,
  SECONDARY_TIME_DIMENSION_TITLES
} from '~/constants/timeDimension'

export const analyticsGetOptions = async (
  dimension: string,
  filters: Filter[],
  scopeType: string
) => {
  // TODO: Remove this logic once employee who are no longer in headcount are removed from kpi table (DATA-525)
  let allFilters: Filter[]
  if (dimension.startsWith('employee')) {
    allFilters = [
      ...filters,
      {
        member: DIMENSION_TITLES.EMPLOYEE_IS_IN_HEADCOUNT,
        operator: FilterOperator.EQUALS,
        values: ['TRUE']
      }
    ]
  } else {
    allFilters = filters
  }
  const { $apiGqlClient } = useNuxtApp()
  return (
    await $apiGqlClient.query({
      query: ANALYTICS_GET_OPTIONS,
      fetchPolicy: 'cache-first',
      variables: { dimension, filters: allFilters, scopeType }
    })
  ).data.analyticsGetOptions as { [dimension: string]: string | number }[]
}

export const analyticsGetQuery = async (
  query: Query,
  scopeType: string
): Promise<ResultSet> => {
  const { $apiGqlClient } = useNuxtApp()
  return (
    await $apiGqlClient.query({
      query: ANALYTICS_GET_QUERY,
      fetchPolicy: 'cache-first',
      variables: { query, scopeType }
    })
  ).data.analyticsGetQuery
}

export const analyticsGetFilterOperatorsForMember = async (
  memberTitle: string,
  memberType: string,
  isScopeFilter: boolean
) => {
  const { $apiGqlClient } = useNuxtApp()
  return (
    await $apiGqlClient.query({
      query: ANALYTICS_GET_FILTER_OPERATORS_FOR_MEMBER,
      fetchPolicy: 'cache-first',
      variables: {
        memberTitle,
        memberType,
        isScopeFilter
      }
    })
  ).data.analyticsGetFilterOperatorsForMember as {
    name: FilterOperator
  }[]
}

export const compareValuesAlphabetically = (
  value1: ResultSetItemValue | DimensionValue,
  value2: ResultSetItemValue | DimensionValue
) => {
  if (value1 === null) return 1
  if (value2 === null) return -1
  return value1.toString().localeCompare(value2.toString(), undefined, {
    numeric: true
  })
}

const buildQueryTimeDimensions = (
  measureShortTitles: string[],
  dimensionShortTitles: string[],
  selectedPeriod: FullPeriod,
  timeOption: QueryTimeOption
): Query['timeDimensions'] => {
  const shouldUseTimeGranularity = measureShortTitles.some(
    measureShortTitle =>
      getMeasureFromTitle(measureShortTitle).meta.shouldUseTimeGranularity
  )

  switch (timeOption.type) {
    case QueryTimeType.CUSTOM_PERIOD:
      return [
        {
          dimension: DIMENSION_TITLES.DATE,
          dateRange: timeOption.dateRange
        }
      ]
    case QueryTimeType.NO_PERIOD:
      return []
    case QueryTimeType.PERIOD_WITH_COMPARISON:
      return [
        {
          dimension: DIMENSION_TITLES.DATE,
          compareDateRange: [
            selectedPeriod.dateRange,
            selectedPeriod.compareDateRange
          ]
        }
      ]
    case QueryTimeType.PERIOD_WITH_TREND_GRAIN:
      return [
        {
          dimension: DIMENSION_TITLES.DATE,
          dateRange: selectedPeriod.trendDateRange,
          ...(shouldUseTimeGranularity
            ? { granularity: selectedPeriod.trendSubPeriod }
            : {})
        }
      ]
    case QueryTimeType.PERIOD:
      if (!shouldUseTimeGranularity) {
        return [
          {
            dimension: DIMENSION_TITLES.DATE,
            dateRange: selectedPeriod.dateRange
          }
        ]
      } else {
        const granularity = getGranularityFromDimensions(
          dimensionShortTitles as DIMENSION_TITLES[]
        )
        return [
          {
            dimension: DIMENSION_TITLES.DATE,
            dateRange: selectedPeriod.dateRange,
            ...(granularity ? { granularity } : {})
          }
        ]
      }

    case QueryTimeType.MULTI_YEAR_PERIOD: {
      const granularity = shouldUseTimeGranularity
        ? getGranularityFromDimensions(
            dimensionShortTitles as DIMENSION_TITLES[]
          )
        : null

      return [
        {
          dimension: DIMENSION_TITLES.DATE,
          compareDateRange: getMultiYearComparePeriods(
            new Date(selectedPeriod.dateRange[0]!),
            new Date(selectedPeriod.dateRange[1]!)
          ),
          // If granularity is equal to 'year' we should not use it because we want YTD data for the last year
          ...(granularity && granularity !== SubPeriodType.YEAR
            ? { granularity }
            : {})
        }
      ]
    }
  }
}

const buildQueryDimensions = (
  dimensions: string[] | undefined,
  selectedPeriod: FullPeriod,
  timeOption: QueryTimeOption
): string[] => {
  if (timeOption.type === QueryTimeType.PERIOD_WITH_TREND_GRAIN) {
    return [DIMENSION_TITLES_BY_TIME_GRAIN[selectedPeriod.trendSubPeriod]]
  }
  return dimensions || []
}

const buildQueryOrder = (
  order: Order[] | undefined,
  measures: [string, ...string[]],
  dimensions: string[],
  timeOption: QueryTimeOption
): Order[] => {
  if (order && order.length > 0) {
    return order
  }

  if (
    timeOption.type === QueryTimeType.PERIOD_WITH_TREND_GRAIN &&
    dimensions[0]
  ) {
    return [[dimensions[0], OrderValue.ASC]]
  }

  return [[measures[0], OrderValue.DESC]]
}

export const buildCompleteQuery = (
  query: PartialQuery,
  timeOption: QueryTimeOption,
  selectedPeriod: FullPeriod,
  additionalFilters: Filter[]
): Query => {
  const dimensions = buildQueryDimensions(
    query.dimensions,
    selectedPeriod,
    timeOption
  )

  return {
    ...query,
    measures: query.measures,
    dimensions,
    timeDimensions: buildQueryTimeDimensions(
      query.measures,
      dimensions,
      selectedPeriod,
      timeOption
    ),
    order: buildQueryOrder(query.order, query.measures, dimensions, timeOption),
    filters: [...(query.filters ?? []), ...additionalFilters]
  }
}

export const getFormattedDimensionValue = (
  value: ResultSetItemValue | undefined,
  dimension: Dimension
) => {
  if (value === null || value === undefined) return EMPTY_VALUE

  if (
    MAIN_TIME_DIMENSION_TITLES.includes(
      dimension.shortTitle as (typeof MAIN_TIME_DIMENSION_TITLES)[number]
    )
  ) {
    return getFormattedMainTimeDimensionPeriod(
      value as string,
      dimension.shortTitle as (typeof MAIN_TIME_DIMENSION_TITLES)[number]
    )
  }

  if (
    SECONDARY_TIME_DIMENSION_TITLES.includes(
      dimension.shortTitle as (typeof SECONDARY_TIME_DIMENSION_TITLES)[number]
    )
  ) {
    return getFormattedSecondaryTimeDimensionPeriod(
      value as number,
      dimension.shortTitle as (typeof SECONDARY_TIME_DIMENSION_TITLES)[number]
    )
  }

  return formatValue(value, dimension.meta.format, false)
}
