import {
  startOfISOWeek,
  setISOWeek,
  endOfISOWeek,
  startOfMonth,
  lastDayOfMonth,
  lastDayOfQuarter,
  setQuarter,
  lastDayOfYear,
  startOfQuarter,
  startOfYear,
  max,
  min,
  addDays,
  addMonths,
  isFuture,
  addQuarters
} from 'date-fns'
import { DIMENSION_TITLES } from '~/constants/analytics'
import {
  ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION,
  ALL_TIME_DIMENSION_TITLES,
  MAIN_TIME_DIMENSION_TITLES,
  ORDERED_TIME_GRAINS,
  SECONDARY_TIME_DIMENSION_TITLES,
  TIME_GRAIN_BY_TIME_DIMENSION
} from '~/constants/timeDimension'
import { getRangeLength } from '~/services/period'
import {
  formatDate,
  formatDate2,
  getDayOfTheWeek,
  getMonth,
  getQuarter,
  getShortMonth,
  getShortYear,
  isDateAfter,
  isDateBefore
} from '~/services/date'
import type { Dimension } from '~/types/cube'
import { SubPeriodType, type FullPeriod } from '~/types/timeDimension'
import type { DimensionWithValue } from '~/types/analytics'

export const getAllowedTimeDimensionsFromDateRange = (
  dateRange: [string, string]
) => {
  const diffDays = getRangeLength(dateRange)
  const maxTimeGrain =
    (diffDays >= 365 && SubPeriodType.YEAR) ||
    (diffDays >= 90 && SubPeriodType.QUARTER) ||
    (diffDays >= 30 && SubPeriodType.MONTH) ||
    (diffDays >= 7 && SubPeriodType.WEEK) ||
    SubPeriodType.DAY
  const maxTimeGrainIndex = ORDERED_TIME_GRAINS.indexOf(maxTimeGrain)
  const allowedTimeGrains = ORDERED_TIME_GRAINS.filter(
    (_, index) => index <= maxTimeGrainIndex
  )
  return (
    Object.keys(
      TIME_GRAIN_BY_TIME_DIMENSION
    ) as (typeof ALL_TIME_DIMENSION_TITLES)[number][]
  ).filter(timeDimensionTitle =>
    allowedTimeGrains.includes(TIME_GRAIN_BY_TIME_DIMENSION[timeDimensionTitle])
  )
}

export const filterAvailableTimeDimensions = (
  dimensions: Dimension[],
  dimensionTitlesAlreadySelected: string[],
  selectedPeriod: FullPeriod
): Dimension[] => {
  const mainTimeDimension = dimensionTitlesAlreadySelected.find(
    dimensionTitle =>
      MAIN_TIME_DIMENSION_TITLES.includes(
        dimensionTitle as (typeof MAIN_TIME_DIMENSION_TITLES)[number]
      )
  ) as (typeof MAIN_TIME_DIMENSION_TITLES)[number] | undefined

  const secondaryTimeDimension = dimensionTitlesAlreadySelected.find(
    dimensionTitle =>
      SECONDARY_TIME_DIMENSION_TITLES.includes(
        dimensionTitle as (typeof SECONDARY_TIME_DIMENSION_TITLES)[number]
      )
  ) as (typeof SECONDARY_TIME_DIMENSION_TITLES)[number] | undefined

  // If main and secondary time dimensions are selected, user can not select any additional time dimension
  if (mainTimeDimension && secondaryTimeDimension) {
    return dimensions.filter(
      dimension =>
        !ALL_TIME_DIMENSION_TITLES.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        )
    )
  }
  // If only main time dimension is selected, user can only select allowed secondary time dimensions
  else if (mainTimeDimension) {
    const allowedTimeDimensions =
      ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION[mainTimeDimension]

    return dimensions.filter(
      dimension =>
        !ALL_TIME_DIMENSION_TITLES.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        ) ||
        allowedTimeDimensions.includes(
          dimension.shortTitle as (typeof SECONDARY_TIME_DIMENSION_TITLES)[number]
        )
    )
  }
  // If only secondary time dimension is selected, user can only select allowed main time dimension
  else if (secondaryTimeDimension) {
    const allowedMainTimeDimension = (
      Object.keys(
        ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION
      ) as (typeof MAIN_TIME_DIMENSION_TITLES)[number][]
    ).find(mainDimension =>
      ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION[
        mainDimension
      ].find(dim => dim === secondaryTimeDimension)
    )!

    return dimensions.filter(
      dimension =>
        !ALL_TIME_DIMENSION_TITLES.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        ) ||
        allowedMainTimeDimension ===
          (dimension.shortTitle as (typeof MAIN_TIME_DIMENSION_TITLES)[number])
    )
  }
  // If no time dimensions are selected, user can select any time dimensions allowed for the selected date range
  else {
    const allowedTimeDimensions = getAllowedTimeDimensionsFromDateRange(
      selectedPeriod.dateRange as [string, string]
    )
    return dimensions.filter(
      dimension =>
        !ALL_TIME_DIMENSION_TITLES.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        ) ||
        allowedTimeDimensions.includes(
          dimension.shortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
        )
    )
  }
}

export const getFormattedMainTimeDimensionPeriod = (
  periodValue: string,
  dimensionTitle: (typeof MAIN_TIME_DIMENSION_TITLES)[number]
): string => {
  const { $t } = useNuxtApp()

  switch (dimensionTitle) {
    case DIMENSION_TITLES.DATE_DAY: {
      return formatDate(periodValue)
    }

    case DIMENSION_TITLES.DATE_WEEK: {
      const [isoYear, isoWeekNumber] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]
      // Set the ISO week and ISO year (starting from the first day of the year)
      const dateInISOWeek = setISOWeek(new Date(isoYear, 0, 4), isoWeekNumber)

      // Get the start of the ISO week (first day of the week)
      const firstDayOfISOWeek = startOfISOWeek(dateInISOWeek)
      return formatDate(firstDayOfISOWeek)
    }

    case DIMENSION_TITLES.DATE_MONTH: {
      const [year, month] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]

      return `${$t(getShortMonth(month))} ${getShortYear(year)}`
    }

    case DIMENSION_TITLES.DATE_QUARTER: {
      const [year, quarter] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]

      return `${$t(getQuarter(quarter))} - ${year}`
    }

    case DIMENSION_TITLES.DATE_YEAR: {
      return periodValue
    }
  }
}

export const getFormattedSecondaryTimeDimensionPeriod = (
  periodValue: number,
  dimensionTitle: (typeof SECONDARY_TIME_DIMENSION_TITLES)[number]
): string => {
  const { $t } = useNuxtApp()

  switch (dimensionTitle) {
    case DIMENSION_TITLES.DATE_QUARTER_OF_THE_YEAR: {
      return $t(getQuarter(periodValue))
    }

    case DIMENSION_TITLES.DATE_MONTH_OF_THE_YEAR: {
      return $t(getMonth(periodValue))
    }

    case DIMENSION_TITLES.DATE_DAY_OF_THE_WEEK: {
      return $t(getDayOfTheWeek(periodValue))
    }

    case DIMENSION_TITLES.DATE_MONTH_OF_THE_QUARTER:
    case DIMENSION_TITLES.DATE_DAY_OF_THE_YEAR:
    case DIMENSION_TITLES.DATE_DAY_OF_THE_MONTH: {
      // Period value is a number
      return periodValue.toString()
    }
  }
}

const getDateRangeFromMainTimeDimension = (
  periodValue: string,
  mainTimeDimensionTitle: (typeof MAIN_TIME_DIMENSION_TITLES)[number]
): [Date, Date] => {
  switch (mainTimeDimensionTitle) {
    case DIMENSION_TITLES.DATE_DAY: {
      return [new Date(periodValue), new Date(periodValue)]
    }

    case DIMENSION_TITLES.DATE_WEEK: {
      const [isoYear, isoWeekNumber] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]
      // Set the ISO week and ISO year (starting from the first day of the year)
      const dateInISOWeek = setISOWeek(new Date(isoYear, 0, 4), isoWeekNumber)
      const startOfIsoWeek = startOfISOWeek(dateInISOWeek)
      const endOfTheISOWeek = endOfISOWeek(dateInISOWeek)
      return [startOfIsoWeek, endOfTheISOWeek]
    }

    case DIMENSION_TITLES.DATE_MONTH: {
      const [year, month] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]
      const firstDay = startOfMonth(new Date(year, month - 1))
      const lastDay = lastDayOfMonth(new Date(year, month - 1))
      return [firstDay, lastDay]
    }

    case DIMENSION_TITLES.DATE_QUARTER: {
      const [year, quarter] = periodValue
        .split('.')
        .map(value => parseInt(value)) as [number, number]

      const firstDay = startOfQuarter(setQuarter(new Date(year, 0, 1), quarter))
      const lastDay = lastDayOfQuarter(
        setQuarter(new Date(year, 0, 1), quarter)
      )
      return [firstDay, lastDay]
    }

    case DIMENSION_TITLES.DATE_YEAR: {
      const year = parseInt(periodValue)
      const firstDay = startOfYear(new Date(year, 0, 1))
      const lastDay = lastDayOfYear(new Date(year, 0, 1))
      return [firstDay, lastDay]
    }
  }
}

export const isPeriodInFuture = (
  periodValue: string,
  dimensionTitle: (typeof MAIN_TIME_DIMENSION_TITLES)[number]
): boolean => {
  const [, periodEndDate] = getDateRangeFromMainTimeDimension(
    periodValue,
    dimensionTitle
  )
  return isFuture(periodEndDate)
}

const getDateRangeFromSecondaryTimeDimension = (
  secondaryPeriodValue: string,
  secondaryTimeDimensionTitle: (typeof SECONDARY_TIME_DIMENSION_TITLES)[number],
  [mainPeriodStartDate, _]: [Date, Date]
): [Date, Date] => {
  // For now secondary time dimension time grain can only be 'day', 'month' or 'quarter'
  const secondaryDimensionTimeGrain = TIME_GRAIN_BY_TIME_DIMENSION[
    secondaryTimeDimensionTitle
  ] as SubPeriodType.DAY | SubPeriodType.MONTH | SubPeriodType.QUARTER

  // Secondary time dimension values can only be number
  const secondaryPeriodOffset = parseInt(secondaryPeriodValue)

  switch (secondaryDimensionTimeGrain) {
    case SubPeriodType.DAY: {
      const day = addDays(mainPeriodStartDate, secondaryPeriodOffset - 1)
      return [day, day]
    }

    case SubPeriodType.MONTH: {
      const periodStartDate = addMonths(
        startOfMonth(mainPeriodStartDate),
        secondaryPeriodOffset - 1
      )
      const periodEndDate = lastDayOfMonth(periodStartDate)
      return [periodStartDate, periodEndDate]
    }

    case SubPeriodType.QUARTER: {
      const periodStartDate = addQuarters(
        startOfQuarter(mainPeriodStartDate),
        secondaryPeriodOffset - 1
      )
      const periodEndDate = lastDayOfQuarter(periodStartDate)
      return [periodStartDate, periodEndDate]
    }
  }
}

export const computeDateRangeForDrilldown = (
  dimensionsWithValue: DimensionWithValue[],
  [selectedStartDate, selectedEndDate]: [Date, Date]
): [string, string] | null => {
  const mainTimeDimensionWithValue = dimensionsWithValue.find(
    ([dimension, _]) =>
      MAIN_TIME_DIMENSION_TITLES.includes(
        dimension.shortTitle as (typeof MAIN_TIME_DIMENSION_TITLES)[number]
      )
  )!

  const secondaryTimeDimensionWithValue = dimensionsWithValue.find(
    ([dimension, _]) =>
      SECONDARY_TIME_DIMENSION_TITLES.includes(
        dimension.shortTitle as (typeof SECONDARY_TIME_DIMENSION_TITLES)[number]
      )
  )

  if (mainTimeDimensionWithValue[1] === null) return null

  const [mainPeriodStartDate, mainPeriodEndDate] =
    getDateRangeFromMainTimeDimension(
      mainTimeDimensionWithValue[1],
      mainTimeDimensionWithValue[0]
        .shortTitle as (typeof MAIN_TIME_DIMENSION_TITLES)[number]
    )

  let [periodStartDate, periodEndDate] = [
    mainPeriodStartDate,
    mainPeriodEndDate
  ]

  // If there is a secondary time dimension we use the secondary period included in the main one, if not we use the main period
  if (secondaryTimeDimensionWithValue) {
    if (secondaryTimeDimensionWithValue[1] === null) return null

    const [secondaryPeriodStartDate, secondaryPeriodEndDate] =
      getDateRangeFromSecondaryTimeDimension(
        secondaryTimeDimensionWithValue[1],
        secondaryTimeDimensionWithValue[0]
          .shortTitle as (typeof SECONDARY_TIME_DIMENSION_TITLES)[number],
        [mainPeriodStartDate, mainPeriodEndDate]
      )

    // If secondary period is partially outside the main period it is invalid
    if (
      isDateBefore(secondaryPeriodStartDate, mainPeriodStartDate) ||
      isDateBefore(secondaryPeriodEndDate, mainPeriodStartDate) ||
      isDateAfter(secondaryPeriodStartDate, mainPeriodEndDate) ||
      isDateAfter(secondaryPeriodEndDate, mainPeriodEndDate)
    ) {
      return null
    }

    periodStartDate = secondaryPeriodStartDate
    periodEndDate = secondaryPeriodEndDate
  }

  // If the period is totally outside the selected date range we return null
  if (
    isDateBefore(periodEndDate, selectedStartDate) ||
    isDateAfter(periodStartDate, selectedEndDate)
  ) {
    return null
  }

  // If the period is partially outside selected date range we need to reduce it
  const [periodApplicableStartDate, periodApplicableEndDate] = [
    max([periodStartDate, selectedStartDate]),
    min([periodEndDate, selectedEndDate])
  ]

  return [
    formatDate2(periodApplicableStartDate),
    formatDate2(periodApplicableEndDate)
  ]
}

export const getGranularityFromDimensions = (
  dimensionShortTitles: DIMENSION_TITLES[]
): SubPeriodType | null => {
  const timeDimensions = dimensionShortTitles.filter(dimensionShortTitle =>
    ALL_TIME_DIMENSION_TITLES.includes(
      dimensionShortTitle as (typeof ALL_TIME_DIMENSION_TITLES)[number]
    )
  ) as (typeof ALL_TIME_DIMENSION_TITLES)[number][]
  if (timeDimensions.length === 0) return null
  return ORDERED_TIME_GRAINS.find(timeGrain =>
    timeDimensions.find(
      dimensionShortTitle =>
        TIME_GRAIN_BY_TIME_DIMENSION[dimensionShortTitle] === timeGrain
    )
  )!
}
