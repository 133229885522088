import { DIMENSION_TITLES } from '~/constants/analytics'
import { SubPeriodType } from '~/types/timeDimension'

export const MAIN_TIME_DIMENSION_TITLES = [
  DIMENSION_TITLES.DATE_DAY,
  DIMENSION_TITLES.DATE_WEEK,
  DIMENSION_TITLES.DATE_MONTH,
  DIMENSION_TITLES.DATE_QUARTER,
  DIMENSION_TITLES.DATE_YEAR
] as const

export const SECONDARY_TIME_DIMENSION_TITLES = [
  DIMENSION_TITLES.DATE_QUARTER_OF_THE_YEAR,
  DIMENSION_TITLES.DATE_MONTH_OF_THE_YEAR,
  DIMENSION_TITLES.DATE_DAY_OF_THE_YEAR,
  DIMENSION_TITLES.DATE_MONTH_OF_THE_QUARTER,
  DIMENSION_TITLES.DATE_DAY_OF_THE_MONTH,
  DIMENSION_TITLES.DATE_DAY_OF_THE_WEEK
] as const

export const DIMENSION_TITLES_BY_TIME_GRAIN: {
  [key in SubPeriodType]: (typeof MAIN_TIME_DIMENSION_TITLES)[number]
} = {
  [SubPeriodType.DAY]: DIMENSION_TITLES.DATE_DAY,
  [SubPeriodType.WEEK]: DIMENSION_TITLES.DATE_WEEK,
  [SubPeriodType.MONTH]: DIMENSION_TITLES.DATE_MONTH,
  [SubPeriodType.QUARTER]: DIMENSION_TITLES.DATE_QUARTER,
  [SubPeriodType.YEAR]: DIMENSION_TITLES.DATE_YEAR
}

export const ALL_TIME_DIMENSION_TITLES = [
  ...MAIN_TIME_DIMENSION_TITLES,
  ...SECONDARY_TIME_DIMENSION_TITLES
] as const

export const TIME_GRAIN_BY_TIME_DIMENSION: {
  [key in (typeof ALL_TIME_DIMENSION_TITLES)[number]]: SubPeriodType
} = {
  [DIMENSION_TITLES.DATE_DAY]: SubPeriodType.DAY,
  [DIMENSION_TITLES.DATE_WEEK]: SubPeriodType.WEEK,
  [DIMENSION_TITLES.DATE_MONTH]: SubPeriodType.MONTH,
  [DIMENSION_TITLES.DATE_QUARTER]: SubPeriodType.QUARTER,
  [DIMENSION_TITLES.DATE_YEAR]: SubPeriodType.YEAR,
  [DIMENSION_TITLES.DATE_QUARTER_OF_THE_YEAR]: SubPeriodType.QUARTER,
  [DIMENSION_TITLES.DATE_MONTH_OF_THE_YEAR]: SubPeriodType.MONTH,
  [DIMENSION_TITLES.DATE_MONTH_OF_THE_QUARTER]: SubPeriodType.MONTH,
  [DIMENSION_TITLES.DATE_DAY_OF_THE_YEAR]: SubPeriodType.DAY,
  [DIMENSION_TITLES.DATE_DAY_OF_THE_MONTH]: SubPeriodType.DAY,
  [DIMENSION_TITLES.DATE_DAY_OF_THE_WEEK]: SubPeriodType.DAY
}

export const ORDERED_TIME_GRAINS = [
  SubPeriodType.DAY,
  SubPeriodType.WEEK,
  SubPeriodType.MONTH,
  SubPeriodType.QUARTER,
  SubPeriodType.YEAR
] as const

export const ALLOWED_SECONDARY_TIME_DIMENSION_BY_MAIN_TIME_DIMENSION: {
  [key in (typeof MAIN_TIME_DIMENSION_TITLES)[number]]: (typeof SECONDARY_TIME_DIMENSION_TITLES)[number][]
} = {
  [DIMENSION_TITLES.DATE_DAY]: [],
  [DIMENSION_TITLES.DATE_WEEK]: [DIMENSION_TITLES.DATE_DAY_OF_THE_WEEK],
  [DIMENSION_TITLES.DATE_MONTH]: [DIMENSION_TITLES.DATE_DAY_OF_THE_MONTH],
  [DIMENSION_TITLES.DATE_QUARTER]: [DIMENSION_TITLES.DATE_MONTH_OF_THE_QUARTER],
  [DIMENSION_TITLES.DATE_YEAR]: [
    DIMENSION_TITLES.DATE_QUARTER_OF_THE_YEAR,
    DIMENSION_TITLES.DATE_MONTH_OF_THE_YEAR,
    DIMENSION_TITLES.DATE_DAY_OF_THE_YEAR
  ]
}
