import {
  getDimensionFromTitle,
  getDimensionTranslationFromTitle
} from './explore'
import { formatDate, yearsToYearsMonthsDays } from './date'
import { addSpaceInLargeNumber, formatLargeNumber } from './number'
import type { ResultSetItemValue } from '~/types/query'
import { MemberFormat } from '~/types/cube'
import { DATA_EXPORT_TYPE } from '~/types/dataExport'
import type { Filter, ScopeFilter } from '~/types/analytics'
import { EMPTY_VALUE } from '~/constants/cube'
import {
  CustomizationFieldType,
  type CustomizationFieldWithOptions
} from '~/types/customization'

export const formatValue = (
  value: ResultSetItemValue,
  format: string,
  withHtml: boolean = true
): string => {
  const { $t } = useNuxtApp()

  if (value === undefined || value === null) return '-'
  switch (format) {
    case MemberFormat.NAME:
      return formatName(value as string)
    case MemberFormat.TRANSLATION_KEYS: {
      const values = (value as string).split('//')
      const valuesWithoutLast = values.length > 1 ? values.slice(0, -1) : values
      return valuesWithoutLast.map(val => $t(val)).join(' / ')
    }

    case MemberFormat.DIMENSION_TITLES: {
      const values = (value as string).split('//')
      const valuesWithoutLast = values.length > 1 ? values.slice(0, -1) : values
      return valuesWithoutLast
        .map(val => getDimensionTranslationFromTitle(val))
        .join(' / ')
    }
    case MemberFormat.DATE:
      // TODO: Change date format according to locale for english speaking users
      return formatDate(value as string)
    case MemberFormat.AGE_YEARS_MONTHS_DAYS: {
      return formatAgeYearsMonthsDays(parseFloat(value as string), withHtml)
    }

    case MemberFormat.AGE_YEARS: {
      const yearsMonthsDays = yearsToYearsMonthsDays(
        parseFloat(value.toString())
      )
      return `${yearsMonthsDays.years} ${$t('date.text.years', {
        count: yearsMonthsDays.years
      })}`
    }

    case MemberFormat.DAYS: {
      return formatDays(parseFloat(value as string), withHtml)
    }

    case MemberFormat.CURRENCY: {
      return `${formatLargeNumber(
        Number(formatNumber(parseFloat(value as string), 0)),
        withHtml
      )}${
        withHtml ? '<span class="medium"></span>' : '' // TODO: Handle correct currency
      }`
    }

    case MemberFormat.CURRENCY_EXACT: {
      return `${addSpaceInLargeNumber(
        formatNumber(parseFloat(value as string), 0)
      )}${withHtml ? '<span class="medium"></span>' : ''}` // TODO: Handle correct currency
    }

    case MemberFormat.PERCENT: {
      return `${parseFloat(formatNumber(parseFloat(value as string), 1))}%`
    }

    case MemberFormat.PERCENT_0: {
      return `${parseFloat(formatNumber(parseFloat(value as string), 0))}%`
    }

    case MemberFormat.NUMBER_0: {
      return formatNumber(parseFloat(value as string), 0)
    }

    case MemberFormat.NUMBER_1: {
      return formatNumber(parseFloat(value as string), 1)
    }

    case MemberFormat.NUMBER_2: {
      return formatNumber(parseFloat(value as string), 2)
    }

    case MemberFormat.BOOLEAN: {
      return formatBoolean(value as string)
    }

    default:
      return value.toString()
  }
}

export const formatValueForExport = (
  value: ResultSetItemValue,
  format: string,
  exportType: DATA_EXPORT_TYPE
) => {
  if (value === undefined || value === null)
    return exportType === DATA_EXPORT_TYPE.CSV ? '-' : ''
  let formattedValue
  switch (format) {
    // We need to display a full and non-formatted value for numeric values
    case MemberFormat.CURRENCY:
    case MemberFormat.CURRENCY_EXACT:
    case MemberFormat.NUMBER_0:
    case MemberFormat.NUMBER_1:
    case MemberFormat.NUMBER_2:
      formattedValue = `${value}` // TODO: Handle correct currency
      break
    case MemberFormat.PERCENT:
      formattedValue = `${value}%`
      break
    default:
      formattedValue = formatValue(value, format, false)
  }
  // We add double quote around the string value so that ',' inside th value are not interpreted as a cell break
  return exportType === DATA_EXPORT_TYPE.CSV
    ? `"${formattedValue}"`
    : formattedValue
}

export const formatEvolution = (evolution: number, format: string) => {
  const result = isFinite(evolution) ? `${Math.abs(evolution)}` : '0'

  switch (format) {
    case MemberFormat.PERCENT: {
      return `${parseFloat(
        parseFloat(result).toFixed(1)
      )}<span class="medium">%</span>`
    }

    case MemberFormat.CURRENCY: {
      return `${formatLargeNumber(
        Number(parseFloat(result).toFixed(0))
      )}<span class="medium">€</span>`
    }

    case MemberFormat.CURRENCY_EXACT: {
      return `${addSpaceInLargeNumber(
        parseFloat(result).toFixed(0)
      )}<span class="medium"> €</span>`
    }

    case MemberFormat.AGE_YEARS: {
      return parseFloat(parseFloat(result).toFixed(1))
    }

    case MemberFormat.AGE_YEARS_MONTHS_DAYS: {
      return formatAgeYearsMonthsDays(parseFloat(result), true)
    }

    case MemberFormat.DAYS: {
      return parseFloat(parseFloat(result).toFixed(1))
    }

    case MemberFormat.NUMBER_0: {
      return parseFloat(result).toFixed(0)
    }

    case MemberFormat.NUMBER_1: {
      return parseFloat(result).toFixed(1)
    }

    case MemberFormat.NUMBER_2: {
      return parseFloat(result).toFixed(2)
    }
    default:
      return result.toString()
  }
}

export const formatStat = (value: ResultSetItemValue, format: string) => {
  const { $t } = useNuxtApp()

  if (value === undefined || value === null) return 'N/A'
  switch (format) {
    case MemberFormat.PERCENT: {
      return `${parseFloat(
        parseFloat(value.toString()).toFixed(1)
      )}<span class="medium">%</span>`
    }

    case MemberFormat.CURRENCY: {
      return `${formatLargeNumber(
        Number(parseFloat(value.toString()).toFixed(0))
      )}<span class="medium">€</span>`
    }

    case MemberFormat.CURRENCY_EXACT: {
      return `${addSpaceInLargeNumber(
        parseFloat(value.toString()).toFixed(0)
      )}<span class="medium"> €</span>`
    }

    case MemberFormat.AGE_YEARS_MONTHS_DAYS: {
      return formatAgeYearsMonthsDays(value as number, true)
    }

    case MemberFormat.AGE_YEARS: {
      const yearsMonthsDays = yearsToYearsMonthsDays(
        parseFloat(value.toString())
      )
      return `${yearsMonthsDays.years}<span class="small"> ${$t(
        'date.text.years',
        { count: yearsMonthsDays.years }
      )}`
    }

    case MemberFormat.DAYS: {
      return formatDays(value as number, true)
    }

    case MemberFormat.NUMBER_0: {
      return (value as number).toFixed(0)
    }

    case MemberFormat.NUMBER_1: {
      return (value as number).toFixed(1)
    }

    case MemberFormat.NUMBER_2: {
      return (value as number).toFixed(2)
    }
    default:
      return value.toString()
  }
}

export const formatChartValue = (
  value: ResultSetItemValue,
  format: string | null
) => {
  const { $t } = useNuxtApp()

  if (value === undefined || value === null) return '-'
  switch (format) {
    case MemberFormat.NAME:
      return formatName(value as string)
    case MemberFormat.DATE:
      return formatDate(value as string)
    case MemberFormat.AGE_YEARS_MONTHS_DAYS: {
      return formatAgeYearsMonthsDays(value as number, false)
    }

    case MemberFormat.AGE_YEARS: {
      const yearsMonthsDays = yearsToYearsMonthsDays(
        parseFloat(value.toString())
      )
      return `${yearsMonthsDays.years} ${$t('date.text.years', {
        count: yearsMonthsDays.years
      })}`
    }

    case MemberFormat.DAYS: {
      return formatDays(value as number, false)
    }

    case MemberFormat.CURRENCY: {
      return `${formatLargeNumber(
        Number(formatNumber(value as number, 0)),
        false
      )}€`
    }

    case MemberFormat.CURRENCY_EXACT: {
      return `${addSpaceInLargeNumber(formatNumber(value as number, 0))}`
    }

    case MemberFormat.PERCENT: {
      return `${parseFloat(formatNumber(value as number, 1))}%`
    }

    case MemberFormat.PERCENT_0: {
      return `${parseFloat(formatNumber(value as number, 0))}%`
    }

    case MemberFormat.NUMBER_0: {
      return formatNumber(value as number, 0)
    }

    case MemberFormat.NUMBER_1: {
      return formatNumber(value as number, 1)
    }

    case MemberFormat.NUMBER_2: {
      return formatNumber(value as number, 2)
    }

    default:
      return value
  }
}

const formatName = (value: string) => {
  return value.split('//')[0]!
}

const formatNumber = (value: number, fractionDigits: number) => {
  return value.toFixed(fractionDigits)
}

const formatBoolean = (value: string | boolean) => {
  const { $t } = useNuxtApp()

  if ((value as boolean) === true || (value as string) === 'true') {
    return $t('cube.enum.boolean.true')
  } else {
    return $t('cube.enum.boolean.false')
  }
}

const formatAgeYearsMonthsDays = (value: number, withHtml: boolean = true) => {
  const { $t } = useNuxtApp()

  const yearsMonthsDays = yearsToYearsMonthsDays(parseFloat(`${value / 365}`))
  let finalText = ''

  if (yearsMonthsDays.years > 0) {
    finalText += `${yearsMonthsDays.years}${
      withHtml ? '<span class="small">' : ''
    } ${$t('date.text.years', { count: yearsMonthsDays.years })}${
      withHtml ? '</span>' : ''
    }`
  }

  if (yearsMonthsDays.months > 0) {
    if (finalText !== '') {
      finalText += `${withHtml ? '<span class="small">, </span>' : ', '}`
    }

    finalText += `${yearsMonthsDays.months}${
      withHtml ? '<span class="small">' : ''
    } ${$t(yearsMonthsDays.years ? 'date.text.M' : 'date.text.months', {
      count: yearsMonthsDays.months
    })}${withHtml ? '</span>' : ''}`
  }

  if (
    !yearsMonthsDays.years &&
    (!yearsMonthsDays.months || yearsMonthsDays.days > 1)
  ) {
    if (yearsMonthsDays.months) {
      yearsMonthsDays.days = Math.floor(yearsMonthsDays.days)
    }

    if (finalText !== '') {
      finalText += `${withHtml ? '<span class="small">, </span>' : ', '}`
    }

    finalText += `${yearsMonthsDays.days}${
      withHtml ? '<span class="small">' : ''
    } ${$t(
      yearsMonthsDays.years || yearsMonthsDays.months
        ? 'date.text.D'
        : 'date.text.days',
      { count: Math.floor(yearsMonthsDays.days) }
    )}${withHtml ? '</span>' : ''}`
  }
  return finalText
}

const formatDays = (value: number, withHtml = false) => {
  const { $t } = useNuxtApp()
  const number = formatNumber(value, 1)

  return `${number} ${withHtml ? '<span class="small">' : ''} ${$t(
    'date.text.days',
    { count: parseInt(number) }
  )}`
}

export const formatFilterValues = (
  filter: ScopeFilter | Filter,
  shouldShrinkValues = false
) => {
  const dimension = getDimensionFromTitle(filter.member)

  const formattedValues = filter.values.map(value =>
    formatValue(value, dimension.meta.format, false)
  )

  if (formattedValues.length === 1) {
    return formattedValues[0] || EMPTY_VALUE
  }

  if (!shouldShrinkValues) {
    const displayedValues = formattedValues.map(value => {
      return value === null ? EMPTY_VALUE : value
    })
    return `${displayedValues.join(', ')}`
  } else {
    const { $t } = useNuxtApp()

    return `${formattedValues[0] || EMPTY_VALUE} ${$t(
      'dashboard.filters.dimension.others',
      { count: formattedValues.length - 1 }
    )}`
  }
}

export const formatCustomizationOptionLabel = (
  customization: CustomizationFieldWithOptions,
  option: string
) => {
  const { $t } = useNuxtApp()

  switch (customization.type) {
    case CustomizationFieldType.MAIN_GROUP_DIMENSION:
    case CustomizationFieldType.STANDARD_FILTER:
      return getDimensionTranslationFromTitle(option)
    case CustomizationFieldType.MONTH:
      return $t(`date.months.${option}`)
    case CustomizationFieldType.TOGGLE:
      return $t(`settings.workspace.preferences.options.${option}`)
  }
}
