import { differenceInDays } from 'date-fns'
import {
  type BasicPeriod,
  type FullPeriod,
  type Period,
  PeriodType,
  SubPeriodType
} from '~/types/timeDimension'
import {
  formatDate2,
  isDateValid,
  getDateMinusDays,
  getDateMinusMonth
} from '~/services/date'

const PERIOD_OPTIONS: {
  [key in Exclude<PeriodType, PeriodType.CUSTOM>]: {
    trendSubPeriod: SubPeriodType
    chartSubPeriod: SubPeriodType
  }
} = {
  [PeriodType.YEAR]: {
    trendSubPeriod: SubPeriodType.QUARTER,
    chartSubPeriod: SubPeriodType.QUARTER
  },
  [PeriodType.QUARTER]: {
    trendSubPeriod: SubPeriodType.MONTH,
    chartSubPeriod: SubPeriodType.MONTH
  },
  [PeriodType.MONTH]: {
    trendSubPeriod: SubPeriodType.MONTH,
    chartSubPeriod: SubPeriodType.WEEK
  }
}

export const getDateQuarterNumber = (date: Date) => {
  return Math.floor(date.getMonth() / 3) + 1
}

export const getQuarterStartDateByYearAndQuarterNumber = (
  year: number,
  quarterNumber: number
) => {
  // In Safari, new Date('2023-1-01') is Invalid Date
  const monthNumber = (quarterNumber - 1) * 3 + 1
  const month = ('0' + monthNumber).slice(-2)

  return new Date(`${year}-${month}-01`)
}

const getMonthStartDateAndEndDate = (year: number, monthNumber: number) => {
  return {
    startDate: new Date(year, monthNumber, 1),
    endDate: new Date(year, monthNumber + 1, 0)
  }
}

export const getCurrentQuarterPeriodOptions = (): Period[] => {
  const todayDate = new Date()
  const currentQuarterNumber = getDateQuarterNumber(todayDate)

  const allPeriodChoices = getAllPeriods()

  if (currentQuarterNumber === 1) {
    return [
      allPeriodChoices.LAST_YEAR_Q4,
      allPeriodChoices.LAST_YEAR,
      allPeriodChoices.LAST_MONTH,
      allPeriodChoices.LAST_12_MONTHS_TO_DATE,
      allPeriodChoices.QTD,
      allPeriodChoices.MTD,
      allPeriodChoices.CURRENT_MONTH,
      allPeriodChoices.CURRENT_YEAR_Q1,
      allPeriodChoices.CURRENT_YEAR
    ]
  }

  if (currentQuarterNumber === 2) {
    return [
      allPeriodChoices.LAST_YEAR,
      allPeriodChoices.CURRENT_YEAR_Q1,
      allPeriodChoices.LAST_MONTH,
      allPeriodChoices.LAST_12_MONTHS_TO_DATE,
      allPeriodChoices.YTD,
      allPeriodChoices.QTD,
      allPeriodChoices.MTD,
      allPeriodChoices.CURRENT_MONTH,
      allPeriodChoices.CURRENT_YEAR_Q2,
      allPeriodChoices.CURRENT_YEAR
    ]
  }

  if (currentQuarterNumber === 3) {
    return [
      allPeriodChoices.LAST_YEAR,
      allPeriodChoices.CURRENT_YEAR_Q1,
      allPeriodChoices.CURRENT_YEAR_Q2,
      allPeriodChoices.LAST_MONTH,
      allPeriodChoices.LAST_12_MONTHS_TO_DATE,
      allPeriodChoices.YTD,
      allPeriodChoices.QTD,
      allPeriodChoices.MTD,
      allPeriodChoices.CURRENT_MONTH,
      allPeriodChoices.CURRENT_YEAR_Q3,
      allPeriodChoices.CURRENT_YEAR
    ]
  } else {
    return [
      allPeriodChoices.LAST_YEAR,
      allPeriodChoices.CURRENT_YEAR_Q1,
      allPeriodChoices.CURRENT_YEAR_Q2,
      allPeriodChoices.CURRENT_YEAR_Q3,
      allPeriodChoices.LAST_MONTH,
      allPeriodChoices.LAST_12_MONTHS_TO_DATE,
      allPeriodChoices.YTD,
      allPeriodChoices.QTD,
      allPeriodChoices.MTD,
      allPeriodChoices.CURRENT_MONTH,
      allPeriodChoices.CURRENT_YEAR
    ]
  }
}

const getAllPeriods = () => {
  const todayDate = new Date()
  const currentYear = todayDate.getFullYear()
  const currentMonth = todayDate.getMonth()
  const currentQuarterNumber = getDateQuarterNumber(todayDate)

  const currentQuarterStartDate = getQuarterStartDateByYearAndQuarterNumber(
    currentYear,
    currentQuarterNumber
  )

  const last12MonthsStart = formatDate2(getDateMinusMonth(todayDate, 12))
  const before12MonthsStart = formatDate2(
    getDateMinusDays(getDateMinusMonth(todayDate, 12), 1)
  )
  const before12MonthsEnd = formatDate2(getDateMinusMonth(todayDate, 24))

  const lastQuarterStart = formatDate2(
    getDateMinusMonth(currentQuarterStartDate, 3)
  )

  const lastQuarterEnd = formatDate2(
    getDateMinusDays(currentQuarterStartDate, 1)
  )

  const { startDate: currentMonthStartDate, endDate: currentMonthEndDate } =
    getMonthStartDateAndEndDate(currentYear, currentMonth)

  const { startDate: lastMonthStartDate, endDate: lastMonthEndDate } =
    getMonthStartDateAndEndDate(currentYear, currentMonth - 1)

  const {
    startDate: beforeLastMonthStartDate,
    endDate: beforeLastMonthEndDate
  } = getMonthStartDateAndEndDate(currentYear, currentMonth - 2)

  return {
    LAST_YEAR: {
      periodId: 'LAST_YEAR',
      periodType: PeriodType.YEAR,
      dateRange: [`${currentYear - 1}-01-01`, `${currentYear - 1}-12-31`],
      isFuture: false,
      compareDateRange: [
        `${currentYear - 2}-01-01`,
        `${currentYear - 2}-12-31`
      ],
      trendDateRange: [`${currentYear - 2}-01-01`, `${currentYear - 1}-12-31`]
    },
    CURRENT_YEAR: {
      periodId: 'CURRENT_YEAR',
      periodType: PeriodType.YEAR,
      dateRange: [`${currentYear}-01-01`, `${currentYear}-12-31`],
      isFuture: true,
      compareDateRange: [
        `${currentYear - 1}-01-01`,
        `${currentYear - 1}-12-31`
      ],
      trendDateRange: [`${currentYear - 1}-01-01`, `${currentYear}-12-31`]
    },
    NEXT_YEAR: {
      periodId: 'NEXT_YEAR',
      periodType: PeriodType.YEAR,
      dateRange: [`${currentYear + 1}-01-01`, `${currentYear + 1}-12-31`],
      isFuture: true,
      compareDateRange: [`${currentYear}-01-01`, `${currentYear}-12-31`],
      trendDateRange: [`${currentYear}-01-01`, `${currentYear + 1}-12-31`]
    },
    LAST_YEAR_Q1: {
      periodId: 'LAST_YEAR_Q1',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear - 1}-01-01`, `${currentYear - 1}-03-31`],
      isFuture: false,
      compareDateRange: [
        `${currentYear - 2}-10-01`,
        `${currentYear - 2}-12-31`
      ],
      trendDateRange: [`${currentYear - 2}-10-01`, `${currentYear - 1}-03-31`]
    },
    LAST_YEAR_Q2: {
      periodId: 'LAST_YEAR_Q2',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear - 1}-04-01`, `${currentYear - 1}-06-30`],
      isFuture: false,
      compareDateRange: [
        `${currentYear - 1}-01-01`,
        `${currentYear - 1}-03-31`
      ],
      trendDateRange: [`${currentYear - 1}-01-01`, `${currentYear - 1}-06-30`]
    },
    LAST_YEAR_Q3: {
      periodId: 'LAST_YEAR_Q3',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear - 1}-07-01`, `${currentYear - 1}-09-30`],
      isFuture: false,
      compareDateRange: [
        `${currentYear - 1}-04-01`,
        `${currentYear - 1}-06-30`
      ],
      trendDateRange: [`${currentYear - 1}-04-01`, `${currentYear - 1}-09-30`]
    },
    LAST_YEAR_Q4: {
      periodId: 'LAST_YEAR_Q4',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear - 1}-10-01`, `${currentYear - 1}-12-31`],
      isFuture: false,
      compareDateRange: [
        `${currentYear - 1}-07-01`,
        `${currentYear - 1}-09-30`
      ],
      trendDateRange: [`${currentYear - 1}-07-01`, `${currentYear - 1}-12-31`]
    },
    CURRENT_YEAR_Q1: {
      periodId: 'CURRENT_YEAR_Q1',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear}-01-01`, `${currentYear}-03-31`],
      isFuture: currentQuarterNumber === 1,
      compareDateRange: [
        `${currentYear - 1}-10-01`,
        `${currentYear - 1}-12-31`
      ],
      trendDateRange: [`${currentYear - 1}-10-01`, `${currentYear}-03-31`]
    },
    CURRENT_YEAR_Q2: {
      periodId: 'CURRENT_YEAR_Q2',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear}-04-01`, `${currentYear}-06-30`],
      isFuture: currentQuarterNumber <= 2,
      compareDateRange: [`${currentYear}-01-01`, `${currentYear}-03-31`],
      trendDateRange: [`${currentYear}-01-01`, `${currentYear}-06-30`]
    },
    CURRENT_YEAR_Q3: {
      periodId: 'CURRENT_YEAR_Q3',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear}-07-01`, `${currentYear}-09-30`],
      isFuture: currentQuarterNumber <= 3,
      compareDateRange: [`${currentYear}-04-01`, `${currentYear}-06-30`],
      trendDateRange: [`${currentYear}-04-01`, `${currentYear}-09-30`]
    },
    CURRENT_YEAR_Q4: {
      periodId: 'CURRENT_YEAR_Q4',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear}-10-01`, `${currentYear}-12-31`],
      isFuture: true,
      compareDateRange: [`${currentYear}-07-01`, `${currentYear}-09-30`],
      trendDateRange: [`${currentYear}-07-01`, `${currentYear}-12-31`]
    },
    CURRENT_MONTH: {
      periodId: 'CURRENT_MONTH',
      periodType: PeriodType.MONTH,
      dateRange: [
        formatDate2(currentMonthStartDate),
        formatDate2(currentMonthEndDate)
      ],
      isFuture: true,
      compareDateRange: [
        formatDate2(lastMonthStartDate),
        formatDate2(lastMonthEndDate)
      ],
      trendDateRange: [
        formatDate2(lastMonthStartDate),
        formatDate2(currentMonthEndDate)
      ]
    },
    NEXT_YEAR_Q1: {
      periodId: 'NEXT_YEAR_Q1',
      periodType: PeriodType.QUARTER,
      dateRange: [`${currentYear + 1}-01-01`, `${currentYear + 1}-03-31`],
      isFuture: true,
      compareDateRange: [`${currentYear}-10-01`, `${currentYear}-12-31`],
      trendDateRange: [`${currentYear}-10-01`, `${currentYear + 1}-03-31`]
    },
    MTD: {
      periodId: 'MTD',
      periodType: PeriodType.CUSTOM,
      dateRange: [formatDate2(currentMonthStartDate), formatDate2(todayDate)],
      isFuture: false,
      compareDateRange: [
        formatDate2(lastMonthStartDate),
        formatDate2(lastMonthEndDate)
      ],
      trendDateRange: [formatDate2(lastMonthStartDate), formatDate2(todayDate)]
    },
    QTD: {
      periodId: 'QTD',
      periodType: PeriodType.CUSTOM,
      dateRange: [formatDate2(currentQuarterStartDate), formatDate2(todayDate)],
      isFuture: false,
      compareDateRange: [lastQuarterStart, lastQuarterEnd],
      trendDateRange: [lastQuarterStart, formatDate2(todayDate)]
    },
    YTD: {
      periodId: 'YTD',
      periodType: PeriodType.CUSTOM,
      dateRange: [`${currentYear}-01-01`, formatDate2(todayDate)],
      isFuture: false,
      compareDateRange: [
        `${currentYear - 1}-01-01`,
        `${currentYear - 1}-12-31`
      ],
      trendDateRange: [`${currentYear - 1}-01-01`, formatDate2(todayDate)]
    },
    LAST_12_MONTHS_TO_DATE: {
      periodId: 'LAST_12_MONTHS_TO_DATE',
      periodType: PeriodType.YEAR,
      dateRange: [last12MonthsStart, formatDate2(todayDate)],
      isFuture: false,
      compareDateRange: [before12MonthsStart, before12MonthsEnd],
      trendDateRange: [before12MonthsStart, formatDate2(todayDate)]
    },
    LAST_MONTH: {
      periodId: 'LAST_MONTH',
      periodType: PeriodType.MONTH,
      dateRange: [
        formatDate2(lastMonthStartDate),
        formatDate2(lastMonthEndDate)
      ],
      isFuture: false,
      compareDateRange: [
        formatDate2(beforeLastMonthStartDate),
        formatDate2(beforeLastMonthEndDate)
      ],
      trendDateRange: [
        formatDate2(beforeLastMonthStartDate),
        formatDate2(lastMonthEndDate)
      ]
    }
  }
}

export const isPeriodInvalid = (startDate: string, endDate: string) => {
  return (
    !isDateValid(startDate) ||
    !isDateValid(endDate) ||
    new Date(startDate) > new Date(endDate)
  )
}

export const getRangeLength = (value: string[]) => {
  const date1 = new Date(value[0]!)
  const date2 = new Date(value[1]!)
  return differenceInDays(date2, date1)
}

const getTrendOrChartSubPeriodFromDiffDays = (diffDays: number) => {
  if (diffDays < 10) {
    return SubPeriodType.DAY
  } else if (diffDays < 60) {
    return SubPeriodType.WEEK
  } else if (diffDays < 180) {
    return SubPeriodType.MONTH
  } else if (diffDays < 730) {
    return SubPeriodType.QUARTER
  } else {
    return SubPeriodType.YEAR
  }
}

export const buildCompletePeriodFromPeriodOption = (periodOption: Period) => {
  if (periodOption.periodType === PeriodType.CUSTOM) {
    return buildCompleteCustomPeriodFromPeriod(periodOption)
  }

  return {
    ...periodOption,
    trendSubPeriod: PERIOD_OPTIONS[periodOption.periodType].trendSubPeriod,
    chartSubPeriod: PERIOD_OPTIONS[periodOption.periodType].chartSubPeriod
  }
}

export const buildCompleteCustomPeriodFromPeriod = (periodOption: Period) => ({
  ...periodOption,
  trendSubPeriod: getTrendOrChartSubPeriodFromDiffDays(
    getRangeLength(periodOption.trendDateRange)
  ),
  chartSubPeriod: getTrendOrChartSubPeriodFromDiffDays(
    getRangeLength(periodOption.dateRange)
  )
})

export const buildCustomPeriodFromDateRange = (dateRange: string[]) => {
  const startDate = new Date(dateRange[0]!)
  const endDate = new Date(dateRange[1]!)
  const todayDate = new Date()
  const isFuture = startDate > todayDate || endDate > todayDate
  const diffDays = getRangeLength(dateRange)
  const compareDateRange = [
    formatDate2(getDateMinusDays(startDate, diffDays + 1)),
    formatDate2(getDateMinusDays(startDate, 1))
  ]
  const trendDateRange = [compareDateRange[0]!, dateRange[1]!]

  return buildCompleteCustomPeriodFromPeriod({
    periodId: 'CUSTOM',
    periodType: PeriodType.CUSTOM,
    dateRange: [dateRange[0]!, dateRange[1]!],
    isFuture,
    compareDateRange,
    trendDateRange
  })
}

export const formatSelectedPeriod = (
  isCustomPeriodSelected: boolean,
  selectedPeriod: BasicPeriod
) => {
  const { $t } = useNuxtApp()

  return {
    label: isCustomPeriodSelected
      ? `${selectedPeriod.dateRange[0]} - ${selectedPeriod.dateRange[1]}`
      : $t(`dashboard.filters.time.choices.${selectedPeriod.periodId}`),
    isFuture: selectedPeriod.isFuture,
    id: selectedPeriod.periodId,
    isCustomChoice: isCustomPeriodSelected
  }
}

export const formatPeriodOptions = (periodOptions: Period[]) => {
  const { $t } = useNuxtApp()

  return periodOptions.map(option => ({
    label: $t(`dashboard.filters.time.choices.${option.periodId}`),
    isFuture: option.isFuture,
    id: option.periodId
  }))
}

export const getPeriodFromDateRange = (
  dateRange: string[],
  periodOptions: Period[]
): FullPeriod => {
  const periodOption = periodOptions.find(
    periodOption =>
      periodOption.dateRange[0] === dateRange[0] &&
      periodOption.dateRange[1] === dateRange[1]
  )

  if (periodOption) {
    return buildCompletePeriodFromPeriodOption(periodOption)
  } else {
    return buildCustomPeriodFromDateRange(dateRange)
  }
}

export const areSamePeriods = (
  period1: [string, string],
  period2: [string, string]
) => period1[0] === period2[0] && period1[1] === period2[1]

export const getMultiYearComparePeriods = (
  startDate: Date,
  endDate: Date
): [string, string][] => {
  const startYear = startDate.getFullYear()
  const numberOfYears = endDate.getFullYear() - startDate.getFullYear() + 1

  return Array.from({ length: numberOfYears }, (_, index) => [
    formatDate2(new Date(startYear + index, 0, 1)),
    index + 1 === numberOfYears
      ? formatDate2(endDate)
      : formatDate2(new Date(startYear + index, 11, 31))
  ])
}

export const getYearsFromDateRange = (
  startDate: Date,
  endDate: Date
): string[] => {
  const startYear = startDate.getFullYear()
  const numberOfYears = endDate.getFullYear() - startDate.getFullYear() + 1

  return Array.from({ length: numberOfYears }, (_, index) =>
    (startYear + index).toString()
  )
}
